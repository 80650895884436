<template>
  <main class="main">
    <canvas id="canvasForWallpaper"></canvas>
    <img src="@/assets/images/background.png" alt="back" class="background" />
    <div class="container">
      <div class="main_wrapper">
        <VControlMenu
          @click="isSelectOpen = !isSelectOpen"
          :openSelect="isSelectOpen"
          @selectImage="selectUserImage"
          @selectColor="updateBackgroundColor"
          @setVAlignment="updateVerticalAlignment"
          :colorOptions="bgColorOptions"
        />
        <VModelPhone
          :bg-color="bgColor"
          :img="chooseImage ? chooseImage : '/boki/0-fs8.png'"
          @downloadDesktopImage="downloadWallpaper('mobile')"
          :valignment="valignment"
        >
        </VModelPhone>
        <VModelDesktop
          :bg-color="bgColor"
          @image="getImage"
          @downloadDesktopImage="downloadWallpaper('desktop')"
          :img="chooseImage ? chooseImage : '/boki/0-fs8.png'"
        >
        </VModelDesktop>
      </div>
    </div>
  </main>
</template>

<script>
import VControlMenu from '@/components/VControlMenu'
import VModelPhone from '@/components/models/VModelPhone'
import VModelDesktop from '@/components/models/VModelDesktop'
import downloadCanvasImage from 'canvas-to-image'
import extractColors from 'extract-colors'
import ColorsGroup from '../utils/ColorsGroup'

const MAX_BG_COLORS = 4

function cloneCanvas(oldCanvas) {
  //create a new canvas
  var newCanvas = document.createElement('canvas')
  var context = newCanvas.getContext('2d')

  //set dimensions
  newCanvas.width = oldCanvas.width
  newCanvas.height = oldCanvas.height

  //apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0)

  //return the new canvas
  return newCanvas
}

function removeImgMiddle(ctx) {
  ctx.fillStyle = '#000'
  ctx.fillRect(1, 1, ctx.canvas.width - 2, ctx.canvas.height - 2)
}

function pixelDataToRgb(pixelData) {
  return [...pixelData].slice(0, 3)
}

export default {
  name: 'VMain',
  components: {
    VControlMenu,
    VModelPhone,
    VModelDesktop,
    // VLogo,
  },
  data() {
    return {
      isSelectOpen: false,
      bgColor: 'B5E5CF',
      bgColorOptions: ['#b4c1f5', '#fff0e2', '#fbcbe0', '#604951'],
      valignment: 'center',
      chooseImage: '/boki/0-fs8.png',
      backgroundFromImage: '',
      image: Object,
      exportImage: Object,
    }
  },
  methods: {
    updateBackgroundColor(color) {
      this.bgColor = color.replace('#', '')
    },
    updateVerticalAlignment(valignment) {
      this.valignment = valignment
    },
    selectUserImage(number) {
      if (number && number <= 7776) {
        this.chooseImage = `/boki/${number}-fs8.png`
      }
      if (number > 7776) {
        alert('Your Boki ID Should be from 0 to 7777')
      }
    },
    getImage(image) {
      this.image = image
      let canvasForWallpaper = document.getElementById('canvasForWallpaper')
      canvasForWallpaper.width = image.width
      canvasForWallpaper.height = image.height
      const ctx = canvasForWallpaper.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)

      const canvasClone = cloneCanvas(canvasForWallpaper)
      const borderOnlyCtx = canvasClone.getContext('2d')
      removeImgMiddle(borderOnlyCtx)
      const borderOnlyFrame = borderOnlyCtx.getImageData(
        0,
        0,
        canvasClone.width,
        canvasClone.height
      )

      const options = {
        distance: 0.3,
        saturationImportance: 0,
        splitPower: 10,
      }

      const left = borderOnlyCtx.getImageData(
        0,
        Math.floor(image.height / 2),
        1,
        1
      ).data
      const top = borderOnlyCtx.getImageData(
        Math.floor(image.width / 2),
        0,
        1,
        1
      ).data
      const right = borderOnlyCtx.getImageData(
        image.width - 1,
        Math.floor(image.height / 2),
        1,
        1
      ).data

      const manuallyExtractedColorsRGB = [left, top, right].map(pixelDataToRgb)
      const manuallyExtractedColors = manuallyExtractedColorsRGB.map((rgb) => {
        return {
          red: rgb[0],
          green: rgb[1],
          blue: rgb[2],
          hex: '#' + this.rgbToHex(...rgb),
        }
      })

      extractColors(borderOnlyFrame, options).then((colors) => {
        colors = colors.filter((color) => color.hex !== '#000000')

        const totalArea = colors.reduce((total, color) => total + color.area, 0)
        colors = colors.map((color) => {
          color.area = color.area / totalArea
          return color
        })

        const combinedColors = [...manuallyExtractedColors, ...colors]

        const colorGroup = new ColorsGroup()
        for (const color of combinedColors) {
          colorGroup.addColor(color.hex, color.red, color.green, color.blue)
        }
        const importantColors = colorGroup.getColors(0.05, 0.2)
        this.bgColorOptions = importantColors
          .map((color) => color.hex)
          .slice(0, MAX_BG_COLORS)
        this.updateBackgroundColor(this.bgColorOptions[0])

        let color = canvasForWallpaper
          .getContext('2d')
          .getImageData(
            1,
            Math.floor(image.height / 2),
            image.width,
            image.height
          ).data

        this.updateBackgroundColor(this.rgbToHex(color[0], color[1], color[2]))
      })
    },
    componentToHex(c) {
      let hex = c.toString(16)
      return hex.length == 1 ? '0' + hex : hex
    },
    rgbToHex(r, g, b) {
      return (
        this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
      )
    },
    downloadWallpaper(device) {
      const valignment = this.valignment
      const newImage = new Image(this.image.width, this.image.height)
      newImage.src = this.image.src
      const canvas = document.querySelector('#canvasForWallpaper')
      canvas.width = device === 'desktop' ? 3840 : 1356
      canvas.height = device === 'desktop' ? 2160 : 2934
      const context = canvas.getContext('2d')
      context.fillStyle = `#${this.bgColor}`
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.save()
      context.beginPath()

      const originalDistanceFromLowerEdge = device === 'desktop' ? 0 : 70 // pixels
      const originalScreenHeight = device === 'desktop' ? 330 : 455 // pixels
      const scaledDistanceFromLowerEdge = originalDistanceFromLowerEdge / originalScreenHeight * canvas.height

      const circleDiameter = 450

      const arcY = valignment === 'center' || device === 'desktop' ? canvas.height / 2 : canvas.height - 2*scaledDistanceFromLowerEdge
      const imageY = valignment === 'center' || device === 'desktop' ? canvas.height / 2 - circleDiameter : canvas.height - 2*scaledDistanceFromLowerEdge - circleDiameter

      context.arc(
        canvas.width / 2,
        arcY,
        circleDiameter,
        0,
        Math.PI * 2,
        false
      )
      context.clip()
      context.drawImage(
        newImage,
        canvas.width / 2 - circleDiameter,
        imageY,
        900,
        900
      )
      context.restore()
      downloadCanvasImage(canvas, {
        name: `boki-${device}`,
        type: 'png',
        quality: 1,
      })
    },
  },
}
</script>

<style lang="scss">
.main {
  position: relative;
  background-color: $secondary;

  #canvas,
  #canvasForWallpaper {
    display: none;
  }

  .background {
    position: absolute;
    pointer-events: none;
    left: 0;
    bottom: 0;
    max-width: 30%;
  }

  .main_wrapper {
    padding: 120px 0 160px;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .main {
    .background {
      display: none;
    }

    .main_wrapper {
      padding: 70px 0 90px;
      flex-wrap: wrap;
      grid-row-gap: 20px;
    }
  }
}
</style>
