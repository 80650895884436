<template>
  <div class="select_wrapper">
    <div class="select_title">
      {{ title }}
    </div>
    <div class="custom_select">
      <div class="paper">
        <div class="colorPicker">
          <div
            v-for="item in options"
            :key="options[item]"
            :class="{
              colorOption: true,
              bright: isBrightColor(item),
              dark: !isBrightColor(item),
              selected: selectedColor === item,
            }"
            @click="selectColor(item)"
            :style="styleOption(item)"
          ></div>
        </div>
        <div class="valignPicker">
          <svg
            @click="selectVAlignment('center')"
            class="valignOption"
            :class="{
              selectedVAlignment: this.selectedVAlignment === 'center',
            }"
            viewBox="0 0 24 24"
          >
            <path
              d="M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3V1h-2v4H8l4 4 4-4zM4 11v2h16v-2H4z"
            ></path>
          </svg>
          <svg
            @click="selectVAlignment('bottom')"
            class="valignOption"
            :class="{
              selectedVAlignment: this.selectedVAlignment === 'bottom',
            }"
            viewBox="0 0 24 24"
          >
            <path d="M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z"></path>
          </svg>
        </div>
      </div>
    </div>

    <!-- @click="selectOption(item)" -->
  </div>
</template>

<script>
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export default {
  name: 'VSelect',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectTitle: {
      type: String,
      default: '',
    },
    itemType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    errorText: {
      type: String,
      default: '',
    },
    optionsWrapperClass: {
      type: String,
      default: '',
    },
  },
  watch: {
    options: function(newOptions) {
      this.selectedColor = newOptions[0]
    },
  },
  data() {
    return {
      selectedColor: this.options[0],
      selectedVAlignment: 'center',
    }
  },
  methods: {
    selectColor(item) {
      this.selectedColor = item
      this.$emit('click', false)
      this.$emit('selectColor', item)
    },

    selectVAlignment(alignment) {
      this.selectedVAlignment = alignment
      this.$emit('click', false)
      this.$emit('selectVAlignment', alignment)
    },

    styleOption(item) {
      return {
        backgroundColor: item,
      }
    },

    isBrightColor(hex) {
      const { r, g, b } = hexToRgb(hex)
      const brightness = r * 0.299 + g * 0.587 + b * 0.114
      return brightness > 150
    },
  },
}
</script>

<style lang="scss">
.select_wrapper {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: grid;
  grid-row-gap: 10px;
  position: relative;
  font-size: 18px;
  border-radius: 8px;

  .select_title {
    width: 100%;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #555555;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    padding-right: 1rem;
  }

  .custom_select {
    align-items: stretch;
    background-color: $secondary_light;
    border-radius: 8px;
    box-sizing: border-box;
    color: #555555;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 70px;
    outline: none;
    padding: 8px 8px;
    position: relative;
    width: 100%;

    .paper {
      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      padding: 8px;
      width: 100%;
    }

    .colorPicker {
      box-sizing: border-box;
      display: flex;
      height: 30px;
      // max-width: 120px;
      width: 100%;
      margin: 0;

      .colorOption {
        font-size: 8px;
        flex-grow: 1;
        position: relative;
        cursor: pointer;
      }

      .colorOption:after {
        background: black;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        display: block;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        opacity: 0;
      }

      .colorOption.dark:after {
        background: white;
      }

      .colorOption.bright:after {
        background: black;
      }

      .colorOption.selected:after {
        opacity: 1;
      }

      .colorOption:first-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      .colorOption:last-of-type {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    .valignPicker {
      display: flex;
      margin: 0 8px;

      .valignOption {
        margin-left: 6px;
        cursor: pointer;
        opacity: 0.2;
        height: 22px;
      }

      .valignOption.selectedVAlignment {
        cursor: default;
        opacity: 1;
      }
    }

    .selected_option {
      width: 100%;
    }

    .select_icon {
      margin-left: 30px;

      .arrow {
        transition: 0.5s ease-in-out;
      }
    }
  }

  .custom-select_options {
    position: absolute;
    box-sizing: border-box;
    top: 100%;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    display: grid;
    border: 2px inherit solid;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    z-index: 999;
    background-color: $secondary_light;
    backdrop-filter: blur(5px);

    .option {
      // cursor: pointer;
      display: grid;
      justify-items: start;
      padding: 5px 18px;
      box-sizing: border-box;
      width: 100%;
    }

    .option:not(:first-child) {
      border-top: 1px inherit solid;
    }
  }

  .error_message {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
  }

  .error {
    border-color: rgba(99, 53, 67, 1);
  }

  .error:focus {
    border-color: rgba(99, 53, 67, 1);
  }

  .error_message {
    color: rgba(235, 93, 93, 1);
  }
}
</style>
