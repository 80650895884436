<template>
  <svg :width="width" :height="height" :viewBox="view" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.86407 1.16604C7.70702 0.759083 8.62021 0.500698 9.56534 0.584673C13.8982 0.736475 18.3333 1.32753 22.2542 3.3171C25.7091 4.9966 28.6402 7.73548 30.5528 11.0977C32.8486 15.135 33.9214 19.8763 33.6277 24.5241C33.6372 25.3121 33.1295 26.0647 32.36 26.2585C30.8817 26.6977 29.3171 26.4975 27.7973 26.5524C22.0403 26.6945 15.9545 24.8696 11.9569 20.5062C7.78365 16.0135 6.10414 9.61523 6.32127 3.56579C6.26379 2.72281 6.51284 1.91859 6.86407 1.16604ZM8.99379 3.44629C8.86607 9.17598 10.4019 15.3514 14.6741 19.3693C19.0038 23.3904 25.2716 24.2463 30.9104 23.7101C31.1148 18.0935 29.1032 12.2055 24.9204 8.39436C20.6067 4.51536 14.6102 3.32679 8.99379 3.44629Z" :fill="color"/>
    <path d="M39.8353 11.5436C43.9958 7.50951 50.0689 6.24989 55.6598 6.63746C56.1579 10.6037 55.382 14.7508 53.5556 18.2939C51.71 21.8822 48.284 24.5339 44.4173 25.519C41.3329 26.3587 38.076 26.4072 34.9245 25.9905C34.4871 20.797 36.1953 15.3063 39.8353 11.5436Z" :fill="color"/>
    <path d="M1.48134 31.5296C2.2253 30.6382 3.47695 30.987 4.47955 30.9127C6.14948 30.9902 7.9152 30.664 9.48934 31.3843C11.1497 32.0884 12.363 33.6807 12.6632 35.4668C13.0495 36.833 12.4875 38.1863 12.1076 39.4782C15.2335 41.8876 15.3165 47.3525 12.0341 49.6489C10.1343 51.0668 7.66934 51.0183 5.41828 50.986C4.55937 50.9795 3.70046 51.0183 2.84155 50.9505C1.76871 50.9053 0.85232 49.8814 0.906601 48.7994C0.887443 43.7286 0.91618 38.6578 0.893829 33.587C0.88425 32.8668 0.91618 32.0496 1.48134 31.5296ZM5.04471 34.9597C4.85632 35.8543 4.76053 36.7877 4.90102 37.6986C5.62902 38.5835 6.97646 38.0151 7.96629 38.0732C8.56976 37.4369 9.0072 36.5068 8.54102 35.6541C7.73958 34.5236 6.20376 35.034 5.04471 34.9597ZM4.85313 42.5077C4.87548 43.9321 4.73818 45.3758 5.03513 46.7776C6.44323 46.6968 7.94713 47.0489 9.28818 46.5224C10.9581 45.8118 10.9134 42.9502 9.16046 42.3947C7.7779 42.0168 6.16225 41.8101 4.85313 42.5077Z" :fill="color"/>
    <path d="M24.3901 31.1775C27.4745 30.5154 30.9165 31.0354 33.4007 33.096C37.6122 36.568 37.9635 43.6446 34.432 47.727C31.0506 51.5641 24.6679 51.9516 20.683 48.8704C16.248 45.4339 15.8776 38.1313 19.5304 33.9874C20.8139 32.576 22.5605 31.6361 24.3901 31.1775ZM25.3799 35.0694C23.8601 35.4182 22.4104 36.3355 21.7048 37.776C20.9544 39.0614 21.0726 40.6021 21.1173 42.0296C21.2514 44.5392 23.4322 46.6386 25.8844 46.7904C27.3532 46.8647 28.9209 46.9067 30.2173 46.0895C32.0181 45.1303 32.9281 43.0018 32.8579 41.0058C32.9409 39.3844 32.4396 37.6726 31.2486 36.5422C29.7288 35.0306 27.4075 34.6301 25.3799 35.0694Z" :fill="color"/>
    <path d="M40.0938 32.9249C40.0012 31.9979 40.7675 31.4166 41.371 30.8707C42.4215 30.8546 43.7114 31.1517 43.9797 32.3564C44.283 34.0392 44.018 35.7768 44.117 37.4789C46.3616 35.2536 48.6286 33.0476 50.9946 30.9515C51.4991 30.945 52.0036 30.9386 52.5113 30.9289C53.4149 31.6717 54.1876 33.0153 53.284 34.0779C51.2948 36.342 48.8968 38.1959 46.815 40.3728C48.6733 42.3075 50.7647 43.9902 52.6263 45.9217C53.581 46.9455 55.0625 47.7691 55.0338 49.3711C54.8007 49.7522 54.574 50.1366 54.3505 50.5241C53.6927 50.7664 52.9551 51.2605 52.2623 50.8342C51.1 50.2205 50.3401 49.074 49.3471 48.2342C47.5175 46.6096 45.7837 44.8881 44.0276 43.1828C43.9605 45.1691 44.0818 47.1587 43.9733 49.145C43.8871 50.4369 42.3065 51.4285 41.1634 50.7309C40.2503 50.3723 40.0236 49.3356 40.0714 48.4441C40.0906 43.27 40.0459 38.0958 40.0938 32.9249Z" :fill="color"/>
    <path d="M58.5404 32.9507C58.4989 32.0012 59.2652 31.3875 59.9454 30.8836C61.1842 30.8352 62.4902 31.5554 62.487 32.9475C62.5796 36.9331 62.4934 40.9219 62.5221 44.9075C62.487 46.5838 62.6754 48.2891 62.273 49.9331C62.008 50.2044 61.743 50.4789 61.4812 50.7535C60.6957 50.8342 59.725 50.9925 59.1279 50.3271C58.5309 49.7813 58.5117 48.8963 58.5149 48.1438C58.55 43.0794 58.4957 38.0151 58.5404 32.9507Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: "VLogo",
  props: {
    width: {
      type: [String, Number],
      default: 63
    },
    height: {
      type: [String, Number],
      default: 52
    },
    view: {
      type: String,
      default: '0 0 63 52'
    },
    color: {
      type: String,
      default: 'white'
    },
  }
}
</script>

<style scoped>

</style>
