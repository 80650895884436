<template>
  <div class="model_wrapper">
    <div class="phone_model">
      <div class="phone_wrapper">
        <div class="content_wrapper" :style="`background-color: #${bgColor};`">
<!--          <div class="model_logo">-->
<!--            <slot name="contentIcon"/>-->
<!--          </div>-->
          <div class="model_content">
            <img :src="img" ref="image" style="border-radius: 50%" class="content" @load="loaded">
          </div>
        </div>
        <img src="@/assets/images/model/desktop.png" alt="iphone model" class="phone">
      </div>
    </div>
    <div class="model_download_btn">
      <VButton secondary
      @click="download">
        <template v-slot:leftIcon>
          <DownloadIcon/>
        </template>
        Download Wallpaper
      </VButton>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "@/components/Icons/DownloadIcon";

export default {
  name: "VModelDesktop",
  components: {
    DownloadIcon
  },
  props: {
    bgColor: {
      type: String,
    },
    img: {
      type: String,
      default: ''
    }
  },
  methods: {
    download() {
      this.$emit('downloadDesktopImage')
    },

    loaded() {
      const onceTrulyLoaded = (callback) => {
        if (this.$refs.image.width === 0) {
          return setTimeout(onceTrulyLoaded.bind(this, callback), 1)
        }
        callback()
      }
      
      onceTrulyLoaded(this.$emit.bind(this, 'image', this.$refs.image))
    }
  }
}
</script>

<style lang="scss" scoped>

.model_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-row-gap: 24px;


  .phone_model {
    width: fit-content;


    .phone_wrapper {
      max-width: 100%;
      width: max-content;
      height: max-content;
      position: relative;
      overflow: hidden;

      .content_wrapper {
        position: absolute;
        top: 4%;
        right: 3%;
        bottom: 26.5%;
        left: 3%;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-row-gap: 20px;
        align-items: center;
        padding: 40px;
        box-sizing: border-box;

        .model_logo {
          max-width: 100%;
          width: fit-content;
          height: fit-content;
        }

        .model_content {
          max-width: 100%;
          width: fit-content;
          height: fit-content;

          .content {
            max-width: 100%;
            width: 150px;
            border-radius: 50% !important;
          }
        }
      }

      .phone {
        position: relative;
        z-index: 10;
        max-width: 100%;
        pointer-events: none;
      }
    }
  }
}

@media (max-width: 1200px) {

  .model_wrapper {
    width: 100%;

    .phone_model {
      width: 100%;

      .phone_wrapper {
        max-width: 100%;
        width: fit-content;
        height: auto;

        .content_wrapper {

          .model_content {
            height: auto;
            display: flex;

            .content {
              width: 30%;
            }
          }
        }
      }
    }
  }
}

</style>
