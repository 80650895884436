<template>
  <div id="app">
    <VHeader/>
    <VMain/>
    <VFooter/>
  </div>
</template>

<script>

import VMain from "@/pages/VMain";
import VHeader from "@/layouts/VHeader";
import VFooter from "@/layouts/VFooter";

export default {
  name: 'App',
  components: {
    VHeader,
    VMain,
    VFooter
  }
}
</script>
