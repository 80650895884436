var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select_wrapper"},[_c('div',{staticClass:"select_title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"custom_select"},[_c('div',{staticClass:"paper"},[_c('div',{staticClass:"colorPicker"},_vm._l((_vm.options),function(item){return _c('div',{key:_vm.options[item],class:{
            colorOption: true,
            bright: _vm.isBrightColor(item),
            dark: !_vm.isBrightColor(item),
            selected: _vm.selectedColor === item,
          },style:(_vm.styleOption(item)),on:{"click":function($event){return _vm.selectColor(item)}}})}),0),_c('div',{staticClass:"valignPicker"},[_c('svg',{staticClass:"valignOption",class:{
            selectedVAlignment: this.selectedVAlignment === 'center',
          },attrs:{"viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.selectVAlignment('center')}}},[_c('path',{attrs:{"d":"M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3V1h-2v4H8l4 4 4-4zM4 11v2h16v-2H4z"}})]),_c('svg',{staticClass:"valignOption",class:{
            selectedVAlignment: this.selectedVAlignment === 'bottom',
          },attrs:{"viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.selectVAlignment('bottom')}}},[_c('path',{attrs:{"d":"M16 13h-3V3h-2v10H8l4 4 4-4zM4 19v2h16v-2H4z"}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }