<template>
  <button class="btn"
          @click="$emit('click')"
          :class="[primary ? 'primary' : secondary ? 'secondary' : '', customClass]">
    <slot name="leftIcon"/>
    <slot></slot>
    <slot name="rightIcon"/>
  </button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss">

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  min-width: fit-content;
  box-sizing: border-box;
  padding: 22px 24px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  transition: all .8s ease;
  cursor: pointer;
  border: none;
  column-gap: 8px;

  img {
    margin: 0;
  }

  &.primary {
    background-color: $primary;
    color: $white;
  }

  &.secondary {
    padding: 22px 24px;
    background-color: transparent;
    border: 1px $primary solid;
    color: $primary;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    transition: .3s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 0;
    }
  }
}

</style>
