<template>
  <div class="model_wrapper">
    <div class="phone_model">
      <div class="phone_wrapper">
        <div class="content_wrapper" :class="valignment" :style="`background-color: #${bgColor}`">
          <div class="model_content">
            <img :src="img" ref="image" class="content" @load="loaded">
          </div>
        </div>
        <img src="@/assets/images/model/iphone.png" alt="iphone model" class="phone">
      </div>
    </div>
    <div class="model_download_btn">
      <VButton secondary
               @click="download">
        <template v-slot:leftIcon>
          <DownloadIcon/>
        </template>
        Download Wallpaper
      </VButton>
    </div>
  </div>
</template>

<script>
import DownloadIcon from "@/components/Icons/DownloadIcon";


export default {
  name: "VModelPhone",
  components: {
    DownloadIcon
  },
  props: {
    bgColor: {
      type: String,
    },
    img: {
      type: String,
      default: ''
    },
    valignment: {
      type: String,
      default: 'center',
    }
  },
  methods: {
    download() {
      this.$emit('downloadDesktopImage')
    },

    loaded() {
      const onceTrulyLoaded = (callback) => {
        if (this.$refs.image.width === 0) {
          return setTimeout(onceTrulyLoaded.bind(this, callback), 1)
        }
        callback()
      }
      
      onceTrulyLoaded(this.$emit.bind(this, 'image', this.$refs.image))
    }
  }
}
</script>

<style lang="scss" scoped>

.model_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-row-gap: 24px;


  .phone_model {
    width: fit-content;


    .phone_wrapper {
      width: max-content;
      height: max-content;
      position: relative;
      overflow: hidden;

      .content_wrapper {
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);
        border-radius: 20px;
        width: calc(100% - 24px);
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 70px 40px;
        box-sizing: border-box;

        .model_logo {
          max-width: 100%;
          width: fit-content;
          height: fit-content;
        }

        .model_content {
          max-width: 100%;
          width: fit-content;
          height: fit-content;

          .content {
            max-width: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .content_wrapper.center {
        justify-content: center;
      }
      .content_wrapper.bottom {
        justify-content: flex-end;
      }

      .phone {
        position: relative;
        z-index: 10;
        max-width: 100%;
        pointer-events: none;
      }
    }
  }
}

</style>
