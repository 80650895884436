<template>
  <footer class="footer">
    <div class="container">
      <div class="footer_wrapper">
        <div class="footer_card_block">
          <div class="avatar_block">
            <div class="avatar">
              <img src="@/assets/images/footer/avatar.svg" alt="avatar logo" class="img">
            </div>
            <div class="social_link">
              <a target='_blank' href="https://twitter.com/andyocn">
                @andyocn
              </a>
            </div>
          </div>
          <div class="contact_block">
            <div class="contact_title">
              Best Way
              to Say Thanks Is To
            </div>
            <div class="contact_btn">
              <a class="link" target='_blank' href="https://twitter.com/andyocn">
                <VButton :customClass="'social'">
                  <template v-slot:leftIcon>
                    <img src="@/assets/images/footer/twitterIcon.svg" alt="twitter icon">
                  </template>
                  Follow for Updates
                </VButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "VFooter",
  components: {}

}
</script>

<style lang="scss" scoped>

.link {
  text-decoration: none;
}
.footer {
  background-color: $secondary_dark;

  .footer_wrapper {
    padding: 50px 0;

    .footer_card_block {
      width: min(516px, 100%);
      padding: 36px 60px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: $secondary_light;
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      grid-gap: 28px;

      .avatar_block,
      .contact_block {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        grid-gap: 15px;
      }

      .avatar_block {

        .avatar {}

        .social_link {
          a {
            text-decoration: none;
            color: $link;
          }
        }
      }

      .contact_block {
        justify-content: flex-start;

        .contact_title {
          width: 100%;
          font-family: Inter, sans-serif;
          font-size: 22px;
          font-weight: 600;
          line-height: 28px;
        }

        .contact_btn {

          .social {
            padding: 22px 33px;
            background-color: $twitter;
            color: $white;

            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: 765px) {

  .footer {

    .footer_wrapper {

      .footer_card_block {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;

        .contact_block {
          justify-content: center;

          .contact_title {
            text-align: center;
          }

          .contact_btn {
            text-decoration: none;

            .social {
              width: 100%;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
