<template>
  <div class="custom_input_wrapper">
    <p v-if="title" class="input_title">
      {{ title }}
    </p>
    <div class="input_wrapper">
      <input
        :type="type"
        :class="inputClass"
        :placeholder="placeholder"
        @input="checkInputVal"
        class="custom_input"
      />
      <VButton primary @click="updateImageNumber">Enter</VButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputClass: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateImageNumber() {
      this.$emit('updateImageNumber');
    },
    checkInputVal($event) {
      // enforce type number
      const sanitizedValue = $event.target.value.replace(/[^0-9]/g, '')
      $event.target.value = sanitizedValue

      // convert `0155` to `155`
      const validatedNumber = String(Number($event.target.value))

      // enforce length <= 4
      if (validatedNumber.length > 4) {
        $event.target.value = validatedNumber.slice(0, 4)
        return
      }

      this.$emit('input', validatedNumber)
    },
  },
}
</script>

<style lang="scss">
.custom_input_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-row-gap: 10px;

  .input_title {
    width: 100%;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #555555;
  }

  .input_wrapper {
    max-width: 100%;
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: row;

    .custom_input {
      background-color: $secondary_light;
      max-width: 100%;
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding: 22px 24px;
      border-radius: 8px;
      outline: none;
      cursor: text;
      box-shadow: none;
      border: none;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: #555555;
      margin-right: 16px;
    }

    .custom_input::-webkit-input-placeholder {
      opacity: 0.5;
    }

    .input_icon {
      position: absolute;
    }
  }
}
</style>
