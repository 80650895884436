<template>
  <section class="control_section">
    <div class="control_wrapper">
      <VInput
        :title="'Enter Your Boki ID:'"
        :placeholder="'1234'"
        :type="'number'"
        @input="selectedImage = $event"
        @updateImageNumber="updateImageNumber"
      />

      <VSelect
        @click="$emit('click')"
        :title="'Choose Background & Position'"
        :is-open="openSelect"
        :options="colorOptions"
        @selectColor="selectColor"
        @selectVAlignment="selectVAlignment"
      />
    </div>
  </section>
</template>


<script>
import VSelect from "@/components/UI/VSelect";


export default {
  name: "VControlMenu",
  components: {
    VSelect
  },
  props: {
    openSelect: {
      type: Boolean
    },
    colorOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedImage: ''
    }
  },
  methods: {
    updateImageNumber() {
      this.$emit('selectImage', this.selectedImage);
    },

    selectColor(color) {
      this.$emit('selectColor', color);
    },

    selectVAlignment(alignment) {
      this.$emit('setVAlignment', alignment);
    },
  }
}
</script>

<style lang="scss">

.control_section {
  min-width: 260px;
}
.control_wrapper {
  width: 100%;
  display: flex;
  flex-direction:column;
  row-gap: 20px;
}
</style>
