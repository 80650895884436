<template>
  <header class="header">
    <div class="container">
      <div class="header_wrapper">
        <div class="logo_wrapper">
          <div class="logo">
            <VLogo />
          </div>
          <div class="logo_text">
            BokiWallpaper.com
          </div>
        </div>
        <div class="user_wrapper">
          <div class="user_info">
            <div class="info_tag">
              created by
            </div>

            <div class="user_name">
              <a target="_blank" href="https://twitter.com/andyocn">
                @andyocn
              </a>
            </div>
          </div>
          <a target="_blank" href="https://twitter.com/andyocn">
            <div class="user_avatar">
              <img class="avatar" src="@/assets/images/header/avatar_twitter.png" alt="">
              <!-- <UserAvatar />
              <div class="avatar_tag">
                <TwitterIcon />
              </div> -->
            </div>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import VLogo from "@/components/Icons/VLogo";
// import TwitterIcon from "@/components/Icons/TwitterIcon";
// import UserAvatar from "@/components/Icons/UserAvatar";

export default {
  name: "VHeader",
  components: {
    VLogo,
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $primary;
  color: $secondary_light;

  .header_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 0;
    box-sizing: border-box;

    .logo_wrapper,
    .user_wrapper {
      display: flex;
      align-items: center;
      grid-column-gap: 16px;
      margin: 0;
    }

    .logo_wrapper {

      .logo {}

      .logo_text {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;

      }
    }

    .user_wrapper {

      .user_info {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: flex-end;
        align-items: center;
        grid-row-gap: 4px;

        .info_tag {
          width: 100%;
          text-align: right;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
        }

        .user_name {
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;

          a {
            color: white;
            text-decoration: none;
          }
        }
      }

      .user_avatar {
        position: relative;
        height: fit-content;

        .avatar {
          width: 80px;
          height: 80px;
        }

        .avatar_tag {
          position: absolute;
          bottom: 0;
          width: max-content;
          height: max-content;
        }
      }
    }
  }
}

@media (max-width: 765px) {

  .header {

    .header_wrapper {

      .logo_wrapper {

        .logo_text {
          display: none;
        }
      }
    }
  }
}
</style>
