import VueRouter from 'vue-router';
import VMain from "@/pages/VMain";



const routes = [
    { path: '/', component: VMain },
];

const index = new VueRouter({
    mode: 'hash', /* For deploy to github pages use mode: 'hash'. This is my first deploy */
    routes,
});

export default index;
