<template>
  <svg :width="width" :height="height" :viewBox="view" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
        stroke="#5B67BE" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.66699 6.66675L8.00033 10.0001L11.3337 6.66675" stroke="#5B67BE" stroke-width="1.8"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 10V2" stroke="#5B67BE" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    width: {
      type: [String, Number],
      default: 16
    },
    height: {
      type: [String, Number],
      default: 16
    },
    view: {
      type: String,
      default: '0 0 16 16'
    },
  }
}
</script>

<style scoped>

</style>
