import Vue from 'vue'
import App from './App.vue'
import components from '/src/components/UI'
import index from '@/router/index'
import VueRouter from 'vue-router'


Vue.config.productionTip = false


components.forEach(component => {
  Vue.component(component.name, component)
})

Vue.use(VueRouter)

new Vue({
  router: index,
  render: h => h(App),
}).$mount('#app')
